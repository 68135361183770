<template>
    <div class="min-vh-100 p-3">
        <!--    <div class="p-3" :style="{'height': __layoutCenterHeightCalculate.px}">-->
        Stojanov peskovnik<br>
        <hr>
        <b-button-toolbar>

            <b-button @click="getScreenSize()">Screen</b-button>
            <b-button class="ml-2" @click="getDomain()">Domain</b-button>
            <b-button class="ml-2" @click="getLocation">Location</b-button>

            <b-button class="ml-2" @click="structure">Structure</b-button>

            <b-button class="ml-2" @click="__copyToClipboard('Text to clipboard!!!')">Copy to clipboard</b-button>

            <b-button class="ml-2" @click="getLayoutHeight()">Layout height</b-button>

            <b-button class="ml-2" @click="editor()">editor</b-button>
        </b-button-toolbar>


        <!--        <div class="mt-2 border" style="height: 150px">-->
        <!--            Viden na mobitelu-->
        <!--        </div>-->

        <!--        <div class="mt-2 border" style="height: 150px">-->
        <!--            Viden na računalniku-->
        <!--        </div>-->

        <!--        <div class="mt-2 border d-md-block" style="height: 150px">-->
        <!--            d-md-block-->
        <!--        </div>-->
        <!--        <div class="mt-2 border d-md-none" style="height: 150px">-->
        <!--            d-md-none-->
        <!--        </div>-->

        <!--        <div class="mt-2 border d-none d-md-block d-xl-none d-xxl-none" style="height: 150px">-->
        <!--            d-sm-none d-md-block d-xl-none d-xxl-none-->
        <!--        </div>-->
        <!--        <div class="mt-2 border d-none d-md-block d-xl-none d-xxl-none" style="height: 150px">-->
        <!--            d-none d-md-block d-xl-none d-xxl-none-->
        <!--        </div>-->
        <!--        <div class="mt-2 border d-none d-md-block" style="height: 150px">-->
        <!--            d-none d-md-block-->
        <!--        </div>-->

        <div class="mt-2 border d-sm-none d-block" style="height: 150px">
            d-sm-none d-block
        </div>
        <!--        <div class="mt-2 border d-sm-inline-block " style="height: 150px">-->
        <!--            d-sm-inline-block-->
        <!--        </div>-->
        <div class="mt-2 border d-none d-md-block d-lg-none" style="height: 150px">
            d-none d-md-block d-lg-none
        </div>
        <!--        <div class="mt-2 border d-lg-block " style="height: 150px">-->
        <!--            d-lg-block-->
        <!--        </div>-->
        <!--        <div class="mt-2 border d-sm-none d-md-block" style="height: 150px">-->
        <!--            d-sm-none d-md-block. Hidden only on sm.-->
        <!--        </div>-->

        <!-- editor -->

        <div
            class=" mt-2 editor-text border"
            v-html="content"
        />
        <!--            style="width: 500px"-->

        <vue-editor
            ref="editor"
            class="mt-3"
            :id="'editor'"
            v-model="content"
            :editorToolbar="customToolbar"
        ></vue-editor>


        <b-form-textarea class="border" v-model="content"/>

                <b-card body-class="p-0 m-0">
                        <b-card-text
                            class="my-2 border"
                            v-html="content"
                        />
                </b-card>

        <div>
            <b-form-textarea v-model="html"></b-form-textarea>
        </div>
    </div>
</template>

<script>

// @group DEVELOPER
import Vue from "vue";

export default {
    name: "SandboxS",
    components: {},
    props: {},
    data() {
        return {
            cookie: null,
            html: null,

            record: {
                id: 1
            },
            uri: {
                getDestinations: `api/v1/_public/get_destinations`,
                getDestination: `api/v1/_public/get_destination`,
            },

            content: 'Danes je lep dan. Jutri bo še lepši.',

            // customToolbar: [
            //     ["bold", "italic", "underline"],
            //     [
            //         { list: "ordered" },
            //         { list: "bullet" }
            //     ],
            //     ["image", "code-block"]
            // ],
            // customToolbar: [
            //     ["bold", "italic", "underline"],
            //     [
            //         { list: "ordered" },
            //         { list: "bullet" }
            //     ],
            //     ["image", "code-block"]
            // ],

            // customToolbar: [
            //     ["bold", "italic", "underline"],
            //     [{ list: "ordered" }, { list: "bullet" }],
            //     [
            //         { align: "" },
            //         { align: "center" },
            //         { align: "right" },
            //         { align: "justify" }
            //     ],
            //     [{ color: [] }]
            // ]

            // customToolbar: [
            //     [{header: [false, 1, 2, 3, 4, 5, 6]}],
            //     ["bold", "italic", "underline", "strike"], // toggled buttons
            //     [{'script': 'sub'}, {'script': 'super'}],
            //     [
            //         {align: ""},
            //         {align: "center"},
            //         {align: "right"},
            //         {align: "justify"}
            //     ],
            //     ["blockquote", "code-block"],
            //     [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
            //     [{indent: "-1"}, {indent: "+1"}], // outdent/indent
            //     [{color: []}, {background: []}], // dropdown with defaults from theme
            //     ["link", "image", "video"],
            //     ["clean"] // remove formatting button
            // ],

            // <-- full toolbar -->
            customToolbar: [
                [{'font': []}],
                [{'header': [false, 1, 2, 3, 4, 5, 6,]}],
                [{'size': ['small', false, 'large', 'huge']}],
                ['bold', 'italic', 'underline', 'strike'],
                [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                [{'header': 1}, {'header': 2}],
                ['blockquote', 'code-block'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'color': []}, {'background': []}],
                ['link', 'image', 'video', 'formula'],
                [{'direction': 'rtl'}],
                ['clean'],
            ],
        }
    },
    mounted() {
        // window.addEventListener('resize', () => {
        //     console.log("window.innerWidth", window.innerWidth, window.innerHeight);
        // })
        // window.removeEventListener("resize", this.resizeHandler);

    },
    methods: {
        editor() {
            // this.$refs.editor.quill.focus();
            console.log("editor -> html", this.$refs.editor.quill.root.innerHTML); // TODO delete
            // this.html = this.$refs.editor.quill.getContent();
        },

        getScreenSize() {
            // console.clear(); // TODO delete
            console.log("-----------------"); // TODO delete
            console.log("screen size", screen.width + "px x " + screen.height + "px");
            console.log("window inner", window.innerWidth + "px x " + window.innerHeight + "px");
            console.log("screen pixelDepth", screen.pixelDepth + "-bit per pixel");
            // console.log("screen colorDepth", screen.colorDepth + "-bit per pixel");
            // console.log("screen available", screen.availWidth + "px x " + screen.availHeight + "px");
        },
        getDomain() {
            console.clear(); // TODO delete
            let url = process.env.VUE_APP_BASE_URL;
            console.log("url", url);

            // <-- missing protocol in test.prlekija... -->
            // let domain = (new URL(url));
            // console.log("domain", domain);
            // console.log("hostname", domain.hostname);
            // console.log("host", domain.host);
            // console.log("pathname", domain.pathname);
            // console.log("protocol", domain.protocol);
            // console.log("search", domain.search);
        },
        getLocation() {
            console.clear(); // TODO delete
            let url = window.location.href;
            console.log("raw url: ", url);

            // <-- window.location.* -->
            console.log("");
            console.log("href", window.location.href);
            console.log("hostname", window.location.hostname);
            console.log("pathname", window.location.pathname);
            console.log("protocol", window.location.protocol);
            console.log("assign", window.location.assign);

            console.log("");
            // <-- domain -->
            let domain = (new URL(url));
            console.log("domain", domain);
            console.log("hostname", domain.hostname);
            console.log("host", domain.host);
            console.log("pathname", domain.pathname);
            console.log("protocol", domain.protocol);
            console.log("search", domain.search);
        },
        structure() {
            console.clear();
            let loop = true;
            let sectorId = 12;
            let sector_id = [1, 2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 14, 15, 16];
            let parent_id = [0, 1, 1, 2, 2, 4, 1, 6, 1, 1, 12, 13, 16, 13];

            let tempIds = [];
            let array = [];
            // <--  -->
            tempIds.push(sectorId);
            let t = [];

            do {
                tempIds.forEach(temp => {
                    t = [];
                    parent_id.forEach((pId, index) => {
                        if (pId === temp) {
                            t.push(sector_id[index]);
                            array.push(sector_id[index]);
                        }
                    });
                });
                tempIds = t;
            } while (t.length > 0)

            // <--  -->
            console.log("---------------------");
            let check = [13, 14, 16, 15];
            console.log("rezultat :", array);
            console.log("mora biti:", check);
        },
        getLayoutHeight() {
            console.clear(); // TODO delete
            // let comp = this.$refs.compTest.clientHeight;
            // let comp = this.$refs.compTest.offsetHeight;
            // console.log("getHeaderHeight: ", comp); // TODO delete
            // console.log("headerHeights: ", this.$root.headerHeights); // TODO delete
            console.log("layoutTop", this.__layoutTopHeightsGet); // TODO delete
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
